<template>
  <!-- 新闻分类 -->
  <div>
    <!-- 文字 -->
    <div class="text">
      <h4>数字通证溢出报表</h4>
    </div>
    <!-- 顶部分类 跳转 -->
    <div class="theQuery">
      <div class="theQuery-Tow">
        <p>按手机号查询</p>
        <el-input v-model="Call" placeholder="请输入内容" prefix-icon="el-icon-mobile-phone"></el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 52px" @click="doFilter">搜索</el-button>
    </div>
    <!-- 中间表格区域 -->
    <el-table :data="data" border style="margin-top: 60px">
      <el-table-column label="序号" prop="createTime" width="180"></el-table-column>
      <el-table-column label="手机号" prop="typeName" width="250"></el-table-column>
      <el-table-column label="数字通证名称" prop="sort" width="250"></el-table-column>
      <el-table-column label="id" prop="remark" width="250"></el-table-column>
      <el-table-column label="应用名" prop="remark" width="250"></el-table-column>
      <el-table-column label="应用金额" prop="remark" width="250"></el-table-column>
      <el-table-column label="已使用金额" prop="remark" width="250"></el-table-column>
      <el-table-column label="当前数字通证数量" prop="remark" width="250"></el-table-column>
      <el-table-column label="溢出数字通证数量" prop="remark" width="250"></el-table-column>
      <el-table-column label="已提数字通证数量" prop="remark" width="250"></el-table-column>
    </el-table>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[1, 2, 3, 4, 5]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalItems"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: '数字通证溢出报表'
}
</script>

<style lang="scss" scoped>
.text {
  h4 {
    margin: 10px;
    color: #409eff;
  }
}
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
.operation {
  display: flex;
  div {
    margin-left: 12px;
    font-size: 12px;
    color: #409eff;
  }
  div:hover {
    cursor: pointer;
  }
}
.theQuery {
  height: 70px;
  display: flex;
  margin-left: 30px;
  .theQuery-Tow {
    width: 200px;
    height: 70px;
    margin-left: 300px;
    margin-right: 600px;
    font-size: 10px;
  }
}
</style>
